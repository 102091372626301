/* src/styles/SettingsPage.css */
.settings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.settings-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 600px;
}

h1 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.settings-section {
  margin-bottom: 1.5rem;
}

.settings-section h2 {
  color: #333;
  margin-bottom: 0.5rem;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.setting-button,
.action-button {
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.setting-button {
  background-color: #e6f2ff;
  color: #2c3e50;
}

.setting-button.active {
  background-color: #2c3e50;
  color: white;
}

.action-button {
  width: 100%;
  margin-top: 10px;
}

.logout-button {
  background-color: #2c3e50;
  color: white;
}

.delete-account-button {
  background-color: #dc3545;
  color: white;
}

.setting-button:hover,
.action-button:hover {
  opacity: 0.9;
}

@media (max-width: 600px) {
  .settings-card {
    padding:1.5rem;
}
}