.dashboard-container {
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.dashboard-content {
  flex: 1;
  padding: 2rem;
  margin-left: 240px; /* Increased to match the new nav width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.dashboard-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 2rem;
}

/* Removed fixed height to allow content to determine card height */
.ant-card {
  width: 100%;
}

.dashboard-charts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 2rem;
}

.monthly-expenditure-chart,
.financial-distribution-chart,
.income-vs-expenditure-chart {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-header h2 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.chart-header select {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.chart-content {
  height: 300px;
  width: 100%;
}

@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 60px; /* Adjust for collapsed sidebar on tablets */
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 0;
    padding: 1rem;
  }

  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .chart-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .chart-header select {
    margin-top: 10px;
    width: 100%;
  }
}