/* styles/HomePage.css */
.home-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a202c 0%, #2d3748 100%);
    color: #fff;
  }
  
  .hero-section {
    text-align: center;
    padding: 4rem 0;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    margin-bottom: 2rem;
  }
  
  .hero-title {
    font-size: 3.5rem;
    font-weight: bold;
    background: linear-gradient(to right, #3b82f6, #60a5fa);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 1rem;
  }
  
  .hero-subtitle {
    font-size: 1.25rem;
    color: #e2e8f0;
    margin-bottom: 2rem;
  }
  
  .stats-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin: 2rem auto;
    max-width: 1200px;
    padding: 0 1rem;
  }
  
  .stat-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.3s ease;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .stat-card:hover {
    transform: translateY(-5px);
  }
  
  .stat-value {
    font-size: 2.5rem;
    font-weight: bold;
    color: #60a5fa;
    margin-bottom: 0.5rem;
  }
  
  .stat-label {
    color: #e2e8f0;
    font-size: 1rem;
  }
  
  .content-section {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  @media (min-width: 1024px) {
    .content-section {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .section-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    padding: 2rem;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease;
  }
  
  .section-card:hover {
    transform: translateY(-5px);
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .section-title i {
    font-size: 1.25rem;
    color: #60a5fa;
  }