.chat-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .chat-container {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .chat-header {
    background-color:  #2c3e50;
    color: white;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .chat-header h2 {
    margin: 0;
    color: white;
  }
  
  .clear-chat-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .clear-chat-btn:hover {
    background-color: #c82333;
  }
  
  .chat-messages {
    height: 400px;
    overflow-y: auto;
    padding: 20px;
  }
  
  .message {
    margin-bottom: 15px;
    display: flex;
  }
  
  .message.user {
    justify-content: flex-end;
  }
  
  .message-content {
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 18px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .message.user .message-content {
    background-color:  #2c3e50;
    color: white;
  }
  
  .message.ai .message-content {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .chat-input {
    display: flex;
    padding: 15px;
    background-color: #f9f9f9;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 20px;
    font-size: 16px;
  }
  
  .chat-input button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color:  #2c3e50;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .chat-input button:hover {
    background-color: #2c3e50;
  }