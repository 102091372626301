.welcome-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .welcome-popup {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    text-align: center;
  }
  
  .welcome-popup h2 {
    margin-top: 0;
  }
  
  .welcome-popup button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }