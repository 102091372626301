body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #2c3e50;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #f0f0f0;
  }
  
  .home-page main {
    padding: 2rem;
  }

  /* Add these styles to your global CSS file */
@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.bg-gradient-animate {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Glass morphism effects */
.glass-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Custom animations */
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.float-animation {
  animation: float 3s ease-in-out infinite;
}
  
  .market-summary, .stock-ticker, .financial-news {
    margin-bottom: 3rem; /* Increased from 2rem to 3rem */
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .navbar-menu a {
    margin-right: 20px; /* Adjust the value as needed */
  }
  
  
  h2 {
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .positive { color: green; }
  .negative { color: red; }
  
  /* Additional styles for better spacing and readability */
  .stock-ticker .stock-item {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .news-container .news-card {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 4px;
  }
  
  /* Add more styles as needed */

  .news-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .news-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
  }
  
  .news-card h3 {
    margin-top: 0;
    color: #333;
  }
  
  .news-card p {
    color: #666;
  }
  
  .news-card a {
    display: inline-block;
    margin-top: 10px;
    color: #1890ff;
    text-decoration: none;
  }
  
  .news-card a:hover {
    text-decoration: underline;
  }
  .stock-ticker {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .stock-chart {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .stock-chart h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
    text-align: center;
  }
  .financial-news {
    margin-bottom: 3rem;
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .financial-news h2 {
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .news-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .news-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
  }
  
  .news-card h3 {
    margin-top: 0;
    color: #333;
  }
  
  .news-card p {
    color: #666;
  }
  
  .news-card a {
    display: inline-block;
    margin-top: 10px;
    color: #1890ff;
    text-decoration: none;
  }
  
  .news-card a:hover {
    text-decoration: underline;
  }

  .container {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .grid {
    display: grid;
    gap: 2rem;
  }
  
  @media (min-width: 1024px) {
    .grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  .bg-white {
    background-color: #ffffff;
  }
  
  .rounded-lg {
    border-radius: 0.5rem;
  }
  
  .shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  
  .font-bold {
    font-weight: 700;
  }
  
  .text-gray-800 {
    color: #2d3748;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .p-6 {
    padding: 1.5rem;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .news-fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }
  
  .news-fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .news-fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .news-fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }
  
  /* Custom scrollbar */
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(96, 165, 250, 0.3);
    border-radius: 2px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(96, 165, 250, 0.5);
  }