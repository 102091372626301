/* src/styles/UserProfile.css */
.user-profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-profile-form {
  display: flex;
  flex-direction: column;
}

.form-section {
  margin-bottom: 30px;
}

.form-section h2 {
  color: #333;
  margin-bottom: 15px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

input, select, .react-select__control {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
}

input:focus, select:focus, .react-select__control--is-focused {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  outline: none;
}

.error {
  color: #dc3545;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.submit-button:hover {
  background-color: #0056b3;
}

.country-option {
  display: flex;
  align-items: center;
}

.country-flag {
  margin-right: 10px;
  width: 24px;
  height: 18px;
}

/* Responsive design */
@media (max-width: 600px) {
  .user-profile-container {
    padding: 15px;
  }

  input, select, .react-select__control {
    font-size: 14px;
  }

  .submit-button {
    width: 100%;
  }
}