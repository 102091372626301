.dashboard-nav {
  width: 240px;
  background-color: #2c3e50;
  color: white;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding: 20px 0;
  overflow-y: auto;
}

.nav-items {
  display: flex;
  flex-direction: column;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: #ecf0f1;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 14px;
}

.nav-item:hover, .nav-item.active {
  background-color: #34495e;
}

.nav-item svg {
  margin-right: 10px;
  font-size: 18px;
}

.logout-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  color: #e74c3c;
  margin-top: 10px;
}

.logout-button:hover {
  background-color: #34495e;
}

@media (max-width: 1024px) {
  .dashboard-nav {
    width: 60px;
  }

  .nav-item span {
    display: none;
  }

  .nav-item svg {
    margin-right: 0;
  }
}